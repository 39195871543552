.dashboard-navbar {
	display: flex;
	align-items: center;
	height: 52px;
	/* padding: 0 1rem; */
	padding-left: 1rem;
	/* background-image: linear-gradient(315deg, #000000 0%, #000000 74%); */
	background-image: linear-gradient(90deg, black, #454545);
}
.profile-container {
	position: relative;
	width: 40px;
	margin-right: 10px;
	/* display: flex;
	align-items: center;
	justify-content: center; */
	height: 40px;
}
.custom-icon {
	display: flex;
	align-items: center;
	justify-content: center;
	color: black;
	background-color: lightgrey;
	padding: 1rem;
	width: 40px;
	height: 40px;
	border-radius: 50%;
	border-radius: 50%;
	font-size: 2.5rem;
	font-weight: 600;
	cursor: pointer;
}
/* .custom-icon:hover {
	color: white;
	cursor: pointer;
} */
.left-aligned {
	text-align: left;
}

.dashboard-brand {
	display: flex;
	align-items: center;
	transition: ease-in-out 500ms;
}

.dashboard-logo {
	margin-right: 1rem;
	transition: ease-in-out 500ms;
}

.dashboard-toggle-button {
	/* color: #cacaca; */
	color: grey;
	margin-right: auto;
	font-size: 1.25rem;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: left;
	border: none;
	background-color: #000000;
}

.dashboard-sidebar {
	width: 5%;
	padding-top: 4rem;
	/* background-image: linear-gradient(315deg, #7f8c8d 0%, #000000 74%); */
	background-color: #454545;
	background-image: linear-gradient(180deg, black, #454545);

	transition: all 0.3s ease-in-out;
	border-radius: 0px;
	box-shadow: 8px 10px 10px rgba(2, 2, 2, 0.2);
}

.dashboard-sidebar.open {
	width: 25rem;
}

.dashboard-sidebar-content {
	display: flex;
	align-items: center;
	flex-direction: column;
	height: 100%;
	width: 100%;
	font-family: Helvetica, Arial, sans-serif;
}

.dashboard-nav {
	font-size: 1.2rem;
}

.dashboard-nav-link {
	margin-bottom: 1rem;
	color: gray;
	padding: 1rem 0 1rem 1rem;
	width: 100%;
	transition: background-color 0.3s;
	text-decoration: none;
	justify-content: left;
}
.side-active {
	background-color: #333;
	border-left: 3px solid white;
	color: #f2f1f1;
}
.dashboard-nav-link:hover {
	background-color: #333;
	border-left: 3px solid white;
	color: #f2f1f1;
}

.dashboard-main-container {
	display: flex;
	justify-content: center;
	height: 100%;
}

.dashboard-content {
	width: 100%;
	justify-self: center;
	align-items: center;
	padding: 1rem;
	font-family: "Poppins", sans-serif;
	transition: margin-left 0.4s;
}

.dashboard-cards-container {
	margin: 2rem 3rem 0 3rem;
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(40rem, 1fr));
	gap: 2rem;
}
.sidebar-text {
	display: flex;
	align-items: center;
	gap: 5px;
}

.sidebar-headings {
	font-size: 2rem;
	left: 0;
}
.fade-in-image {
	animation: fadeIn 3s;
}

@keyframes fadeIn {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

.card {
	font-size: medium;
	background-image: linear-gradient(315deg, #7c8282 0%, #000000 74%);
	box-shadow: 0 6px 10px rgba(0, 0, 0, 0.08), 0 0 6px rgba(0, 0, 0, 0.05);
	transition: 1s transform cubic-bezier(0.155, 1.105, 0.295, 1.12),
		0.3s box-shadow,
		0.3s -webkit-transform cubic-bezier(0.155, 1.105, 0.295, 1.12);
	padding: 1.4rem 8rem 1.8rem 3.6rem;
	cursor: pointer;
	height: 20rem;
	text-align: left;
	border-radius: 4px;
}

@media (max-width: 1200px) {
	.card {
		justify-content: center;
		width: 100%;
	}
}

.card:hover {
	transform: scale(1.01);
	box-shadow: 0 10px 20px rgba(0, 0, 0, 0.12), 0 4px 8px rgba(0, 0, 0, 0.06);
}

.card-title {
	color: white;
	width: 100%;
	border-left: 2px solid red;
	background-color: rgb(137, 137, 137, 0.4);
	padding-left: 0.6rem;
}

.card-text {
	color: grey;
	height: 6rem;
	width: 100%;
	/* padding-left: 0.6rem; */
}

.card-img {
	filter: saturate(100%);
}

.dropdown-menu {
	box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2); /* Add a subtle shadow effect */
	border-radius: 5px; /* Rounded corners */
	padding: 10px 0; /* Add some padding inside the menu */
}

.dropdown-item {
	color: #333; /* Change the font color */
	font-weight: bold; /* Make the text bold */
	padding: 8px 20px; /* Add some padding to each item */
	transition: all 0.3s ease-in-out; /* Add a smooth transition effect */
}

.dropdown-item:hover {
	background-color: #f5f5f5; /* Add a background color on hover */
}

.content {
	margin-top: 56px;
	margin-left: 200px;
	padding: 20px;
}

@media (max-width: 991.98px) {
	.content {
		margin-left: 60px;
	}
}

/* Sidebar toggle */
.nav {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	overflow: hidden;
	height: 100%;
}

.nav-logo,
.nav-link {
	display: grid;
	grid-template-columns: max-content max-content;
	align-items: center;
	column-gap: 2rem;
	padding: 0.5rem 0 0.5rem 1.5rem;
}

.nav-logo {
	margin-bottom: 4rem;
}

.nav-logo-icon {
	font-size: 1.25rem;
	cursor: pointer;
}

.nav-logo-name {
	font-weight: 1200;
	color: var(--white-color);
}

.side-active {
	color: var(--white-color);
	color: white;
	background-color: rgba(0, 0, 0, 0.1);
}

.side-active::before {
	content: "";
	position: absolute;
	left: 0;
	top: 0;
	height: 38px;
	width: 3px;
	background-color: var(--white-color);
	transition: 0.5s;
}

.show {
	left: 0;
}
.space-toggle {
	padding-left: calc(var(--nav-width) + 1rem);
}

.nav-logo img {
	width: 100%;
	height: 100%;
	display: block;
}
.fade-in-image {
	animation: fadeIn 2s;
}

@keyframes fadeIn {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}
.close_nav {
	width: auto;
	height: 4rem;
	margin-left: -2rem;
	margin-top: 4rem;
}
.open_nav {
	width: auto;
	height: 5rem;
	margin-left: -6px;
	display: flex;
	align-items: center;
	justify-content: center;
}
.open_nav img {
	width: auto;
	height: 5rem;
	margin-left: -1rem;
	margin-right: 4rem;
	margin-top: 5rem;
}
