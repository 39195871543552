.App {
	text-align: center;
	display: grid;
	grid-template-rows: 52px 8fr;
}
html {
	/* 62.5% of 16px browser font size is 10px */
	font-size: 62.5%;
}
#root {
	--primary: #c43026;
	--primary-light: #e64849;
	--secondary: #202239;
	--accent: #600be0;
	--bg: #fafdf8;
	--text: #2f3c63;
	--text-black: #000;
	--text-white: #fff;
	--shadow: #eaeaea;
	font-family: Inter, system-ui, Avenir, Helvetica, Arial, sans-serif;

	height: 100%;
	width: 100%;
	display: grid;
	background-color: #fff;
}
body {
	height: 100vh;
	margin: 0;
	padding: 0;
}
