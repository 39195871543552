/* Dropdown css */
.profile-dropdown {
	width: auto;
	height: auto;
	background-color: #fff;
	border: 1px solid #ccc;
	/* padding: 10px; */
	box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
	position: absolute;
	top: 53px;
	right: 10px;
	font-size: large;
	cursor: pointer;
	z-index: 1;
}
.profile-dropdown > div {
	display: flex;
	align-items: center;
	justify-content: left;
	/* gap: 0.5rem; */
	padding: 10px;
}
.profile-dropdown > div:hover {
	background-color: #f1f1f1;
}

.userProfileContainer {
	cursor: pointer;
	width: 35px;
	height: 35px;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: lightgrey;
}

.dropdownItem {
	padding: 10px;
	cursor: pointer;
	transition: background-color 0.3s ease;
	/* color: #343537; */
	color: gray;
	transition: 500ms ease;
}

.dropdownItem:hover {
	background-color: #f1f1f1;
	color: black;
	fill: black;
}
.listItem {
	display: flex;
	align-items: center;
	justify-content: left;
	gap: 10px;
}
